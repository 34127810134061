.loading {
    width: 64px;
    height: 64px;
    position: absolute;
    top: 50%;
    left: 50%;
    z-index: 100;
    transform: translate(-50%, -50%) scale(1);
  }
  
  .loading-gfx rect, .loading-gfx circle, .loading-gfx path {
    stroke: var(--primary-color);
    stroke-dasharray: 150 150;
    stroke-dashoffset: -150;
    
  }
  
  .loading-gfx rect {
    animation: dash 3s 1s linear infinite;
  }
  
  .loading-gfx path {
    animation: dash 3s 2s linear infinite;
  }
  
  .loading-gfx circle {
    animation: dash 3s 0s linear infinite;
  }
  
  
  @keyframes dash {
    0% {
      stroke-dashoffset: -150;
    }
    33% {
      stroke-dashoffset: 150;
    }
    100% {
      stroke-dashoffset: 150;
    }
  }

  .loading-line {
    width: 100%;
    height: 100%;
    overflow: hidden;
    animation: WriteMask .5s linear;
    mask-size: 100% 100%;
    mask-repeat: no-repeat;
    position:absolute;
    top: 0;
    right: 0;
  }

  @keyframes WriteMask {
    0% {
      width: 0;
    }
    100% {
      width: 100%;
    }
  }

  .loading-line-gfx {
    width: 100%;
    height: 100%;
    animation: WritingLine .75s .5s linear infinite;
    background-repeat: repeat-x;
  }


@keyframes WritingLine {
  0% {
    background-position: 0 center;
  }
  100% {
    background-position: -64px center;
  }
}