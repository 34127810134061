.edit-block {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.edit-buttons {
    margin: 16px 0;
    display: flex;
    justify-content: space-between;
}