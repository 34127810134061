.top-bar {
    position: absolute;
    width: 100%;
    z-index: 3;
    display: flex;
    display: flex;
    flex-direction: row-reverse;
  }

  .edit-bar {
    position: absolute;
    width: 100%;
    height: 64px;
    background: #E5E5E5;
    display: flex;
    align-items: center;
  }

  .edit-bar .button {
    background: transparent;
  }

  .edit-instruction {
    flex-grow: 1;
    text-align: center;
    font-size: 20px;
  }

  .right-buttons {
    flex-shrink: 0;
    display: flex;
    padding: 12px ;
  }

  .right-buttons .button {
    margin-left: 16px;
    position: relative;
  }

  .right-buttons.is-loading .print span{
    opacity: 0;
  }

  .left-pad {
    width: 180px;
    flex-shrink: 2;
  }

  .right-buttons .loading {
    transform: translate(-50%, -50%) scale(.5);
  }