.intro {
    display: flex;
    height: 100%;
    flex-direction: column;
    justify-content: center;
    padding: 10% 10% 40%;
}

.intro p {
    font-size: 18px;
    margin: 16px 0;
    line-height: 24px;
}

.intro-book {
    width: calc(80%);
    height: calc(80%);
    background: #F44336;
    border: solid 2px black;
    border-radius: 16px;
    transform: rotate(-3deg);
    display: flex;
    padding: 12px;
    min-width: 960px;
    min-height: 480px;
    animation: IntroBook 1.5s;
}

.intro-page {
    height: 100%;
    border: 1px solid;
    background: white;
    flex-grow: 1;
    position: relative;
    flex-shrink: 0;
    width: 50%;
}

.intro-page::before {
    position: absolute;
    border-top-left-radius: 48px;
    content: "";
    height: 48px;
    width: calc(100% - 1px);
    bottom: 0;
    background-color: #9E9E9E;
    border: 1px solid black;
    background-image: repeating-linear-gradient(0deg, black, black 1px, transparent 1px, transparent);
    background-size: 8px 8px;
}

.left-page.intro-page::before {
    border-top-left-radius: 0;
    border-top-right-radius: 48px;
}

.right-page {
    border-top-left-radius: 24px;
}

.left-page {
    border-top-right-radius: 24px;
}

.intro-page .loading {
    transform: rotate(2.5deg) translate(-50%, -50%) scale(1);
}


.button .label {
    display: none;
}

.button .label:nth-child(1) {
    display: inline;
}

.button.not-ready .label:nth-child(1) {
    display: none;
}

.button.not-ready .label:nth-child(2) {
    display: inline;
}

.button .label:nth-child(2) span {
    animation-fill-mode: both;
    display: inline-block;
    margin: 0 2px;
}

.button .label:nth-child(2) span:nth-child(1) {
    animation: NotReady 3s infinite linear;
}
.button .label:nth-child(2) span:nth-child(2) {
    animation: NotReady 3s .5s infinite linear;
}
.button .label:nth-child(2) span:nth-child(3) {
    animation: NotReady 3s 1s infinite linear;
}

@keyframes NotReady {
    0% {
        visibility:hidden;
    }
    50% {
        visibility: hidden;
    }
    100% {
        visibility:visible;
    }
}