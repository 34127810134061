.page.cover {
    padding: 0 !important;
}

.book-cover {
    
    flex-grow: 1;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 25% 10% 10% 10%;
    align-items: center;
    position: relative;
    overflow: hidden;
  }
  
  .book-title {
    font-size: 32px;
    font-family: "Sigmar", sans-serif;
    text-align: center;
    line-height: 38px;
    color: var(--primary-color);
    text-shadow: -2px 2px 0 white;
    flex-grow: 1;
    width: 100%;
    z-index: 1;
  }

  .book-title input {
    background-color: rgba(255, 255, 255, .8);
  }
  
  .image-input {
    width: 80%;
    z-index: 2;
  }


  .image-input textarea {
    min-height: 160px;
    padding-left: 8px;
    background-color: rgba(255, 255, 255, .8);
  }


.book-cover .loading {
  transform: translate(-50%, -50%) scale(1.5);
}

.book-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center center;
  z-index: 0;
  transition: filter 1s;
}

.is-loading .book-image {
  filter: blur(48px);
}