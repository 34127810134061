.layout {
    width: 100%;
    height: 100%;
    display: flex;
    box-shadow: 0 5px 16px rgba(0, 0, 0, .5);
    perspective-origin: center center;
    position: relative;
}


.PageBack, .PageForward {
    animation: PageIndex 1.5s;
}


.PageForward .layout {
    animation: LayoutEnter 1.5s ease-out;
}

.PageBack .layout{
    animation: LayoutExit 1.5s ease-in;
    opacity: 0;
}

@keyframes PageIndex {
    0% {
        z-index: 10;
        pointer-events: none;
    }
    100% {
        z-index: 10;
        pointer-events: none;
    }
}

@keyframes LayoutEnter {
    0% {
        transform: perspective(500px) scale(2) rotate3d(0, 1, .5, 15deg) translate(120%, -5%);
    }
    100% {
        transform: perspective(500px) scale(1) rotate3d(0, 1, .5, 0deg) translate(0, 0);
    }
}

@keyframes LayoutExit {
    0% {
        transform: perspective(500px) scale(1) rotate3d(0, 1, .5, 0deg) translate(0, 0);
        opacity: 1;
    }
    100% {
        transform: perspective(500px) scale(2) rotate3d(0, 1, .5, 15deg) translate(120%, -5%);
        opacity: 1;
    }
}
  
.layout .page {
    background: white;
    flex-grow: 1;
    width: 50%;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10%;
    min-width: 480px;
    min-height: 480px;
}

.text {
    font-size: 18px;
    line-height: 24px;
}
  
