html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline; 
  box-sizing: border-box;
}

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
  display: block; }

body {
  line-height: 1; }

ol, ul {
  list-style: none; }

blockquote, q {
  quotes: none; }

blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none; }

table {
  border-collapse: collapse;
  border-spacing: 0; }

a, a:visited {
  text-decoration: none;
  color: inherit; }

:root {
  --bg-color: #fff;
  --bg-color-dark: #000;
  --text-color: #303030;
  --text-color-gray: #EEEEEE;
  --color-gray: #ADADAD;
  --color-gray-dark: #787878;
  --input-bg: #D9D9D9;
  --primary-color: #F44336;
  --secondary-color: #67DAA8;
}

html, body, .app, .wrapper {
  width: 100vw;
  height: 100vh;
  font-family: "Balsamiq Sans", sans-serif;
  box-sizing: border-box;
}

.app {
  overflow: hidden;
}

.app-bg {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  top:0;
  left: 0;
  z-index: -1;
}

.bg-gfx {
  width: 100%;
  height: 200%;
  position: absolute;
  top: 0;
  left: 0;
  background-image: radial-gradient(circle, #2196F3, #2196F3 20%, #00BCD4 20%, #00BCD4 40%, #8BC34A 40%, #8BC34A 60%, #FFC107 60%, #FFC107 80%, #FF5722 80%, #FF5722);
}

.screen {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

.content {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: auto;
  animation: DontScroll 1.5s;
}

.page-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 10%;
}

button, textarea, input {
  border: none;
  outline: none;
}

button {
  cursor: pointer;
}

.button-holder {
  display: flex;
  justify-content: center;
  padding: 24px 0;
}

input {
  background-color: white;
  font-size: 18px;
  font-family: monospace, monospace;
  min-width: 320px;
  width: 80%;
  height: 32px;
  padding: 0 8px;
}

textarea {
  border: none; 
  font-size: 18px;
  min-height: 320px;
  line-height: 32px;
  resize: none;
  background-image: repeating-linear-gradient(0deg, #cdcdcd, #cdcdcd 1px, transparent 1px, transparent);
  background-size: 32px 32px;
  background-color: rgba(255, 255, 255, 0);
  font-family: monospace, monospace;
}

label {
  font-size: 20px;
  margin-bottom: 18px;
}

.logo {
  font-size: 32px;
  margin-bottom: 24px;
  font-family: "Sigmar", sans-serif;
  text-align: center;
  line-height: 38px;
}

.logo span {
  display: inline-block;
  background: var(--primary-color);
  width: 40px;
  height: 40px;
  border-radius: 50%;
  text-align: center;
  color:white;
}

@keyframes IntroBook {
  0% {
    transform: translate(400px, 400px) rotate(3deg);
  }
  100% {
    transform: translate(0, 0) rotate(-3deg);
  }
}

@keyframes DontScroll {
  0% {
    overflow: hidden;
  }
  100% {
    overflow: hidden;
  }
}



@keyframes OpenCover {
  0%{
    transform: rotate3d(0, 1, 0, 0);
  }
  100%{
    transform: rotate3d(0, 1, 0, -89deg);
  }
}


.button {
  font-size: 16px;
  font-weight: bold;
  height: 40px;
  border-radius: 24px;
  font-family: "Balsamiq Sans", sans-serif;
  position: relative;
}

.button:active {
  filter: brightness(85%);
}

.button:disabled {
  pointer-events: none;
}


.primary-btn {
  background-color: var(--primary-color);
  color: white;
  flex-grow: 0;
  padding: 0 16px;
  cursor: pointer;
}

.button.large-btn {
  font-size: 24px;
  height: 48px;
  transform: rotate(3deg);
  padding: 0 24px;
  min-width: 240px;
}

.icon-btn {
  min-width: 40px;
  min-height: 40px;
  background: rgba(0, 0, 0, .1);
  border-radius: 24px;
  display: flex;
  align-items: center;
  color: var(--primary-color);
  padding: 0 8px;
}


.icon-btn:hover {
  background: #E5E5E5;
}
.icon {
  display: block;
  width: 24px;
  height: 24px;
  mask-size: 100% 100%;
  background-color: var(--primary-color);
}

.icon-btn.med-icon, .icon-btn.med-icon .icon {
  width: 48px;
  height: 48px;
}

.icon-btn.large-icon, .icon-btn.large-icon .icon {
  width: 64px;
  height: 64px;
  border-radius: 32px;
  padding: 12px;
}

.simple-btn {
  color: var(--primary-color);
  background-color: transparent;
}


.nav-btn {
  position: absolute;
  top: calc(50% - 32px);
  z-index: 5;
  animation: FadeIn 1.5s;
}

.nav-btn.right {
  right: 16px;
}

.nav-btn.left {
  left: 16px;
  transform: rotate(180deg);
}

@keyframes FadeIn {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}






