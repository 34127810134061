* {
    -webkit-print-color-adjust: exact !important;   /* Chrome, Safari 6 – 15.3, Edge */
    color-adjust: exact !important;                 /* Firefox 48 – 96 */
    print-color-adjust: exact !important;           /* Firefox 97+, Safari 15.4+ */
  }

.print-wrapper {
    width: 100vw;
    height: 100vh;
}

.print-wrapper .screen {
    position: relative;
    width: 100vw;
    height: 100vh;
}

.print-wrapper .page:nth-child(2) {
    padding: 5%;
}

@media print {
    @page {
      margin: 0 in;
      size: landscape;
    }
}