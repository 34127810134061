.illustration {
    width: 100%;
    height: 100%;
    position: relative;
}

.image {
    width: 100%;
    height: 100%;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center center;
    mask-size: contain;
    mask-repeat: no-repeat !important;
    mask-position: center center;
    position: relative;
    filter: blur(0);
    transition: filter 1s;
}


.illustration .loading {
    transform: translate(-50%, -50%) scale(1.5);
}
  
.is-loading .image {
    filter: blur(48px);
}

.image-edit {
    position: absolute;
    width: 100%;
    padding-top: 100%;
    height: 0;
    top: 50%;
    transform: translate(0, -50%);
   
}

.image-edit textarea {
    min-height: 0;
    height: 100px;
    background-color: rgba(255, 255, 255, .8);
}
