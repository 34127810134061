.postit-screen {
    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    z-index: 500;
    display: flex;
    justify-content: center;
    align-items: center;
}

.postit {
    width: 320px;
    min-height: 320px;
    background-color: #FFEB3B;
    border: 2px solid black;
    padding: 24px;
    line-height: 32px;
    font-size: 19px;
    border-bottom-right-radius: 25%;
    position: relative;
    overflow: hidden;
    animation: ShowPostit 1s;

}

@keyframes ShowPostit {
    0% {
        transform: perspective(500px) scale(1.5) rotate3d(1, 0, 0, 60deg) translate(-50%, -50%);
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    100% {
        transform: perspective(500px) scale(1) (1, 0, 0, 0) translate(0, 0);
        opacity: 1;
    }
}

.postit p {
    margin-bottom: 16px;
}

.postit::before {
    content: "";
    width: 80px;
    height: 100px;
    background: transparent;
    border-bottom-right-radius: 100%;
    box-shadow: 0 40px 0 rgba(0, 0, 0, .5);
    position: absolute;
    bottom: 32px;
    right: -8px;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    
}

.postit::after {
    content: "";
    width:48px;
    height: 48px;
    border-bottom-right-radius: 100%;
    background-color: #FFEB3B;
    border-right: 2px solid black;
    border-bottom: 2px solid black;
    position: absolute;
    bottom:-12px;
    right: 48px;
}
